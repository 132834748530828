<template>
    <div class="k-page-flex">
        <div class="k-page-title">
            <h1>Carte des installations</h1>
        </div>

        <MapPOIViewer :pois="pois" />

        <div class="k-flex-center">
            <b-form-radio-group v-model="filter">
                <b-form-radio value="all">Tout voir</b-form-radio>
                <b-form-radio value="type:Table">Tables</b-form-radio>
                <b-form-radio value="type:Borne">Bornes</b-form-radio>
                <b-form-radio value="upToDate:true">
                    Données à jour
                </b-form-radio>
                <b-form-radio value="upToDate:false">
                    Données pas à jour
                </b-form-radio>
            </b-form-radio-group>
        </div>
    </div>
</template>

<script>
import MapPOIViewer from "@/components/map/MapPOIViewer";

export default {
    components: {
        MapPOIViewer,
    },

    data: function() {
        return {
            installations: [],
            filter: "all",
        };
    },

    computed: {
        pois() {
            const today = new Date().toDateString();
            const [filterMode, filterValue] = this.filter.split(":");

            return this.installations
                .filter(i => {
                    i.isUpToDate =
                        new Date(i.contactedAt).toDateString() === today;
                    switch (filterMode) {
                        case "type":
                            return i.type === filterValue;
                        case "upToDate":
                            return i.isUpToDate.toString() === filterValue;
                        default:
                            return true;
                    }
                })
                .map(i => {
                    const href = this.$router.resolve({
                        name: "AdminProject",
                        params: { projectId: i.ProjectId },
                    }).href;

                    return {
                        id: i.id,
                        lng: i.longitude,
                        lat: i.latitude,
                        color: i.isUpToDate ? "#4D4" : "#D44",
                        popup: _buildPopup(i, href),
                    };
                });
        },
    },

    methods: {
        async fetchInstallations() {
            this.installations = await await this.$store.dispatch(
                "admin/getInstallations",
            );
        },
    },

    async beforeMount() {
        await this.fetchInstallations();
    },
};

function _buildPopup(install, href) {
    return `
<b>
    <span class="text-kalkin-2">${install.type}</span>
    ${install.name}
</b><br/>
<small>${install.place || "Pas d'adresse"}</small>
<a
    class="btn btn-xs btn-kalkin-1 mt-2 d-block text-light"
    href="${href}"
>
    Voir projet
</a>
`;
}
</script>
